import React, { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { IAppConfig } from "ict24h-themes-react/lib/esm/App";
import { Ict24hThemes, useThemesConfig } from "ict24h-themes-react";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import AppRouters from "./AppRouter";
import { message } from "antd";

import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin

import "dayjs/locale/vi";
import isInICTMobileApp from "./utils/is-ict-mobile-app";

dayjs.locale("vi");

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(quarterOfYear);
dayjs.extend(isLeapYear); // use plugin

const isInMobileApp = isInICTMobileApp();

const appConfig: IAppConfig = {
  authAPIEndpoint: " https://approval.zbh-dev.i24h.app",
  localStorageKey: "i24h-dev-approval-auth",
  userAPIEndpoint: " https://approval.zbh-dev.i24h.app/user",
  loginProvider: "ms-obh", // NOTE: đang lỗi do app mobile của MS cũng dùng react native webview // isInMobileApp ? undefined : "ms-obh",
  routerType: "browser",
};

function App() {
  const [forgeReady, setForgeReady] = React.useState<string>();

  const themeConfig = useThemesConfig();

  // call when rendering the component
  // initLoginToken(ssoLoginSuccess,ssoLoginFailure, setCurrentUser, setLoading).catch(error => console.error(error));
  useEffect(() => {
    // Initialize the Microsoft Teams SDK
    microsoftTeams.app
      .initialize()
      .then(() => {
        // Get the user context from Teams and set it in the state
        // microsoftTeams.app.getContext().then((context: microsoftTeams.app.Context) => {
        //   setCurrentUser(context?.user)
        // });

        microsoftTeams.authentication
          .getAuthToken()
          .then((result: string) => {
            themeConfig.initLoginToken = result;
            // set cái này để render lại
            setForgeReady(result);
          })
          .catch((err) => {
            if (isInMobileApp) return;
            // TODO: handle error
            console.log(JSON.stringify(err, null, 4));
            message.error("Không thể xác minh tài khoản của bạn");
          });
      })
      .catch((error) => {
        console.log("error: >>>>>> ", JSON.stringify(error, null, 4));
        console.log(error);
        message.error("Không thể kết kết nối Microsoft 365");
      });
  }, []);
  // Xác định xem có nên render ứng dụng hay không
  const isRender = isInMobileApp
    ? true // Nếu đang chạy trong ứng dụng di động, luôn render
    : themeConfig.initLoginToken && forgeReady
    ? true // Nếu không phải ứng dụng di động, chỉ render khi có token đăng nhập và Forge đã sẵn sàng
    : false; // Trong các trường hợp khác, không render

  return (
    <>
      {themeConfig.initLoginToken && forgeReady ? (
        <Ict24hThemes config={appConfig} appRouters={AppRouters} />
      ) : (
        <>
          <h3>Loading...</h3>
          {/*<Button onClick={showConsentDialog} >open win</Button>*/}
        </>
      )}
    </>
  );
}

export default App;
