import { Col, Row, Tooltip } from "antd";
import styles from "./HomeListApp.module.css";
import { Link } from "react-router-dom";
import { QUAN_LY_PHE_DUYET_PREFIX_URL } from "../../../quan-ly-phe-duyet/constants/config.quan-ly-phe-duyet";
import { useThemesConfig } from "ict24h-themes-react";
import { useEffect } from "react";
import { useState } from "react";

function isUsersAccessAI(email?: string) {
  if (!email) return false;
  const ACCESS_EMAILS = ["hoa@ict24h.net", "tung@ict24h.net"];
  return ACCESS_EMAILS.includes(email);
}

const HomeListApp = () => {
  const { getCurrentUser } = useThemesConfig();
  const [canAccessAI, setCanAccessAI] = useState<boolean>(false);

  useEffect(() => {
    if (typeof getCurrentUser === "function") {
      getCurrentUser().then((user) => {
        setCanAccessAI(isUsersAccessAI(user?.email));
      });
    }
  }, [getCurrentUser]);

  // hàm hỗ trợ tạo ra item
  function generalList(list: any[]) {
    return list.map((app) => {
      const isInternalApp = app.belongsTo?.includes("kpi-app");
      return (
        <Col key={app.name || app.url} xs={8} sm={6} md={4} lg={3}>
          <Tooltip title={app.name} color="blue">
            {isInternalApp ? (
              <Link to={app.url}>
                <div className={styles.appIconContainer}>
                  <div>
                    <img src={app.icon} width="45" />
                    <p>{app.name}</p>
                  </div>
                </div>
              </Link>
            ) : (
              <a
                href={app.url}
                target={app.openNewTab ? "_blank" : undefined}
                rel="noreferrer"
              >
                <div className={styles.appIconContainer}>
                  <div>
                    <img src={app.icon} width="45" />
                    <p>{app.name}</p>
                  </div>
                </div>
              </a>
            )}
          </Tooltip>
        </Col>
      );
    });
  }

  const listAppNew = [
    {
      name: "Contract management",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/ptc-dev/apps-container/apps/index.html#/app/contract",
      openNewTab: false,
      belongsTo: ["kpi-app"],
      icon: "https://img.icons8.com/?size=64&id=g6TAghOxvHSt&format=png",
    },
    {
      name: "Tài sản CNTT",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/ptc-dev/apps-container/apps/index.html#/app/asset",
      openNewTab: false,
      belongsTo: ["kpi-app"],
      icon: "https://img.icons8.com/?size=64&id=55150&format=png",
    },
    // {
    //   name: "Xin nghỉ phép",
    //   url: "https://i24h.sharepoint.com/sites/dev2/customer-view/ptc-dev/apps-container/apps/index.html#/app/workLeave/draft",
    //   openNewTab: false,
    //   belongsTo: ["kpi-app"],
    //   icon: "https://img.icons8.com/?size=64&id=46641&format=png",
    // },
    {
      name: "QL Phê duyệt",
      url: QUAN_LY_PHE_DUYET_PREFIX_URL,
      openNewTab: false,
      belongsTo: ["kpi-app"],
      icon: "https://img.icons8.com/?size=64&id=twPPoi69TEic&format=png",
    },
    {
      name: "Biên soạn Hợp đồng Khách hàng",
      url: "https://contract-to-customer-public-view.i24h.app/#/config",
      openNewTab: false,
      belongsTo: ["kpi-app"],
      icon: "https://img.icons8.com/?size=64&id=QanbId3SGVR7&format=png",
    },
    // {
    //   name: "KPI",
    //   url: "/danh-gia-kpi/dashboard-kpi",
    //   openNewTab: false,
    //   belongsTo: ["kpi-app"],
    //   icon: "https://img.icons8.com/?size=64&id=4tSyQosZoCw3&format=png",
    // },

    // {
    //   name: "Làm thêm giờ",
    //   url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/apps/default.aspx#/app/workOvertime",
    //   openNewTab: true,
    //   belongsTo: [],
    //   icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/344/external-overtime-business-flaticons-lineal-color-flat-icons.png",
    // },

    // {
    //   name: "Chấm công",
    //   url: "/cau-hinh-cham-cong/cham-cong",
    //   openNewTab: false,
    //   belongsTo: [],
    //   icon: "https://img.icons8.com/?size=64&id=DN2tvEZ6n36K&format=png",
    // },

    {
      name: "Cấu hình Quy trình",
      url: "/config-workflows",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/?size=64&id=HzqNEMUSstpY&format=png",
    },
  ];

  // đẩy App AI vào đầu
  if (canAccessAI) {
    const name = "Document AI Assistant";

    // tìm xem đã có trong listAppNew chưa
    const isExist = listAppNew.find((app) => app.name === name);
    if (!isExist) {
      listAppNew.unshift({
        name,
        url: "https://documents-ai.i24h.app/documents?from=teams",
        openNewTab: false,
        belongsTo: ["kpi-app"],
        icon: "https://img.icons8.com/?size=64&id=jzsskqK99rN5&format=png",
      });
    }
  }

  const listAppHRM = generalList([
    {
      name: "Nhân sự",
      url: "/hrm/view-employee/cau-hinh-thong-tin/nhan-vien/my-page",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/?size=64&id=qDNClnB7Z4Ky&format=png",
    },
    {
      name: "KPI",
      // url: "/kpi",
      url: "/hrm/danh-gia-kpi/dashboard-kpi",
      openNewTab: false,
      belongsTo: ["kpi-app"],
      icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/2x/external-kpi-media-agency-flaticons-lineal-color-flat-icons.png",
    },

    {
      name: "Nghỉ phép",
      url: "/hrm/cau-hinh-thong-tin/nghi-phep",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/fluency/344/leave.png",
    },
    // {
    //   name: "Làm thêm giờ",
    //   url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/apps/default.aspx#/app/workOvertime",
    //   openNewTab: true,
    //   belongsTo: [],
    //   icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/344/external-overtime-business-flaticons-lineal-color-flat-icons.png",
    // },

    {
      name: "Tài sản",
      url: "/quan-ly-tai-san/tai-san",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/external-itim2101-lineal-color-itim2101/344/external-laptop-computer-technology-itim2101-lineal-color-itim2101-1.png",
    },
    {
      name: "Tính lương",
      url: "/hrm/bang-luong/bao-cao",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/dusk/344/payroll.png",
    },
    {
      name: "Chấm công",
      url: "/hrm/view-dm/cham-cong/cham-cong-cua-toi",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/external-filled-outline-berkahicon/344/external-finger-back-to-work-filled-outline-berkahicon.png",
    },
    {
      name: "Phỏng vấn",
      url: "/hrm/quan-ly-tuyen-dung/ung-vien",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/external-icongeek26-linear-colour-icongeek26/344/external-interview-office-icongeek26-linear-colour-icongeek26.png",
    },
    {
      name: "Bảng chấm công",
      url: "/hrm/view-hr/cham-cong/lich-cham-cong",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/344/external-attendance-human-resources-flaticons-lineal-color-flat-icons.png",
    },
    {
      name: "QL Đào tạo",
      url: "/quan-ly-dao-tao",
      openNewTab: false,
      belongsTo: ["kpi-app"],
      icon: "https://img.icons8.com/?size=64&id=-Es6TVTSyruJ&format=png",
    },
    {
      name: "Điều chuyển nhân sự",
      url: "/quan-ly-dieu-dong-luan-chuyen-nhan-su",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/?size=64&id=nzGGOW4zBEbj&format=png",
    },
    {
      name: "Ứng viên",
      url: "/hrm/quan-ly-tuyen-dung/ung-vien",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/?size=64&id=8mxvqaIqaRUx&format=png",
    },
    {
      name: "Tuyển dụng",
      url: "/hrm/quan-ly-tuyen-dung/ung-vien",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/?size=64&id=CBLBoifEHVDh&format=png",
    },
  ]);

  const listAppOffice = generalList([
    {
      name: "Hợp đồng",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/apps/default.aspx#/app/contract",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/344/external-contract-modelling-agency-flaticons-lineal-color-flat-icons-2.png",
    },
    {
      name: "Văn thư đi/đến",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/apps/default.aspx#/app/document-in",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-letter-traditional-marketing-flaticons-flat-flat-icons.png",
    },
    {
      name: "Helpdesk",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/apps/default.aspx#/app/helpdesk",
      openNewTab: true,
      belongsTo: [],
      icon: "https://icons-for-free.com/iconfiles/png/128/chat+comment+speech+support+talk+icon-1320184207010295916.png",
    },
    // {
    //     name:"Cấu hình công ty",
    //     url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/admin/default.aspx#/config/app",
    //     openNewTab: true,
    //     belongsTo: [],
    //     icon:"https://img.icons8.com/office/344/services--v1.png"
    // },
    {
      name: "Quy trình",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/approval/default.aspx#/approval",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-vitaliy-gorbachev-lineal-color-vitaly-gorbachev/344/external-planning-business-vitaliy-gorbachev-lineal-color-vitaly-gorbachev.png",
    },
    {
      name: "Document ISO",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/apps/default.aspx#/app/document-iso",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-itim2101-lineal-color-itim2101/344/external-folder-stationery-and-office-itim2101-lineal-color-itim2101.png",
    },
    {
      name: "Chứng từ",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/khai-anh/apps/eoffice/default.aspx#/app/document-iso/list-record",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/cotton/344/receipt-approved.png",
    },
    {
      name: "Giao việc",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/khai-anh/apps/task/default.aspx",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-becris-lineal-color-becris/344/external-task-business-management-becris-lineal-color-becris.png",
    },
    {
      name: "Bookings",
      url: "https://i24h.sharepoint.com/sites/dev2/thuan/crossdomain/index.html#/book_resource",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/344/external-booking-rage-room-flaticons-flat-flat-icons.png",
    },
  ]);

  const constructionApps = [
    {
      name: "Quản lý Dự án",
      url: "/pms",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/?size=64&id=6R735OAB4eCV&format=png",
    },
    {
      name: "Dự án CĐT",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/default.aspx#/duan",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-wanicon-lineal-color-wanicon/344/external-construction-real-estate-wanicon-lineal-color-wanicon.png",
    },
    {
      name: "Hồ sơ dự thầu",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/default.aspx#/hosoduthau",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/344/external-engineer-industry-flaticons-lineal-color-flat-icons-2.png",
    },
    {
      name: "Báo cáo tuần",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/default.aspx#/baocaotuan",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/344/external-audit-accounting-flaticons-lineal-color-flat-icons.png",
    },
    {
      name: "Đánh giá CHT",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/default.aspx#/sutuanthu",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-itim2101-lineal-color-itim2101/344/external-construction-engineering-itim2101-lineal-color-itim2101.png",
    },
    {
      name: "QL ATVSLĐ",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/default.aspx#/congtacquanlyantoan",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-icongeek26-linear-colour-icongeek26/344/external-construction-city-life-icongeek26-linear-colour-icongeek26.png",
    },
    {
      name: "Đánh giá an toàn",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/default.aspx#/hosoduthau",
      openNewTab: true,
      belongsTo: [],
      icon: "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/2x/external-safety-factory-flaticons-lineal-color-flat-icons.png",
    },
  ];

  if (checkIsSysRole()) {
    constructionApps.push({
      name: "QLDA ext",
      url: "/ext",
      openNewTab: false,
      belongsTo: [],
      icon: "https://img.icons8.com/external-wanicon-lineal-color-wanicon/344/external-construction-real-estate-wanicon-lineal-color-wanicon.png",
    });
  }

  const listAppConstruction = generalList(constructionApps);

  const listAppOther = generalList([
    {
      name: "Trung Nguyên",
      url: "https://i24h.sharepoint.com/sites/dev2/TrungNguyen/SitePages/Home.aspx",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "Lịch khám",
      url: "https://i24h-4fe15217aa6986.sharepoint.com/sites/dev2/TrungNguyen/PhongKham/app.html#/lichkham/lich",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "KTG",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/KTG",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "Mercedes-Benz",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/mercedes-benz/",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "Tesla School",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/tesla-school/hieutruong.html",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "Dược phẩm",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/duoc-pham/home.html#/khachhang",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "QSR Vietnam",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/nhan-su/demo.aspx",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "Thang máy",
      url: "https://i24h-4fe15217aa69a8.sharepoint.com/sites/dev2/TrungNguyen/ThangMay/",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "Mitsubishi Motor",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/mitsubishi-motors",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "OPES",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/OPES-BM/",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "Golden Gate",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/memo/demo.aspx",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "NKID",
      url: "https://i24h.sharepoint.com/sites/dev2/customer-view/tini/home.aspx",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "AVA Windows",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/subsite",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
    {
      name: "TMS Group",
      url: "https://i24h.sharepoint.com/sites/dev2/beta/contruction/cdt/",
      openNewTab: true,
      belongsTo: [],
      icon: "",
    },
  ]);

  const DU_AN_ID = "/pms/du-an/ff7d4de1-c339-4c1f-8f57-dd3cb882a9ba";

  const listAppPMS = [
    {
      name: "Tổng quan Dự án",
      url: DU_AN_ID,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=6xFnSHEX6VWR&format=png",
    },
    {
      name: "Hồ sơ văn bản",
      url: `${DU_AN_ID}/v2-document-folder`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=67363&format=png",
    },
    {
      name: "QL Giao việc",
      url: `${DU_AN_ID}/quan-ly-giao-viec/tasks`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=t5LSVwaomF6K&format=png",
    },
    {
      name: "QL Hợp đồng trong Dự án",
      url: `${DU_AN_ID}/hop-dong`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=xcanu10oHoR7&format=png",
    },
    {
      name: "Nguồn vốn / Chi phí",
      url: `${DU_AN_ID}/nguon-von`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=1pT3n1iXOEuU&format=png",
    },
    {
      name: "Tiến độ thi công",
      url: `${DU_AN_ID}/ke-hoach-tien-do-thi-cong`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=iTHeUzNHpxws&format=png",
    },
    {
      name: "Nhật ký công trường",
      url: `${DU_AN_ID}/nhat-ky-cong-truong`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=YutoVkdHRcAz&format=png",
    },
    {
      name: "Đề xuất cung ứng",
      url: `${DU_AN_ID}/de-xuat-cung-ung`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=v94lc5XJihf6&format=png",
    },
    {
      name: "Nhà thầu",
      url: `${DU_AN_ID}/danh-sach-nha-thau`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=iKwDC0dEKn1u&format=png",
    },
    {
      name: "QL Rủi ro",
      url: `${DU_AN_ID}/rui-ro-du-an`,
      openNewTab: false,
      belongsTo: ["pms"],
      icon: "https://img.icons8.com/?size=64&id=qA0W4eVHkkCV&format=png",
    },
  ];

  return (
    <>
      <div className={styles.cardBgOpacity}>
        <h1>
          TEAMS <small>Các ứng dụng mới</small>
        </h1>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          {generalList(listAppNew)}
        </Row>
      </div>
      <div className={styles.cardBgOpacity}>
        <h1>
          HRM <small>Quản lý nguồn lực nhân sự</small>
        </h1>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>{listAppHRM}</Row>
      </div>
      <div className={styles.cardBgOpacity}>
        <h1>
          PMS <small>Quản lý dự án</small>
        </h1>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          {generalList(listAppPMS)}
        </Row>
      </div>
      <div className={styles.cardBgOpacity}>
        <h1>
          eOffice <small>Văn phòng điện tử trực tuyến</small>
        </h1>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          {listAppOffice}
        </Row>
      </div>

      <div className={styles.cardBgOpacity}>
        <h1>
          Construction{" "}
          <small>Khối công trường SX-TC, Cơ điện, Ban an toàn</small>
        </h1>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          {listAppConstruction}
        </Row>
      </div>
      <div className={styles.cardBgOpacity}>
        <h1>
          Others <small>Các ứng dụng chuyên ngành khác</small>
        </h1>
        <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
          {listAppOther}
        </Row>
      </div>
    </>
  );
};

export default HomeListApp;

// HELPER FUNC
function checkIsSysRole() {
  const admin = localStorage.getItem("sysadmin");
  return admin === "hoa";
}
